<template>
  <div class="home">
    <div class="content">
      <a-row class="top">
        <a-col :span="12" class="left">
          <div class="banner">
            <a-carousel arrows :autoplay='autoplay' :afterChange="bannerDetail">
              <div slot="prevArrow" slot-scope="props" class="custom-slick-arrow" style="left: 10px;zIndex: 1">
                <a-icon type="left-circle" />
              </div>
              <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
                <a-icon type="right-circle" />
              </div>
              <div class="bannerDiv" v-for="(item, index) in bannerList" :key="index">
                <a class="bannerA" @click="watch(item)" :href="item.href">
                  <img class="bannerImg" :src="resBasePath+'/'+item.livePhotoCover" alt="">
                </a>
              </div>
            </a-carousel>
          </div>
        </a-col>
        <a-col :span="12" class="right">
          <div class="timeLine">
            <div class="plan">直播安排</div>
            <div v-if="list.length<=0">
              <a-empty></a-empty>
            </div>
            <a-steps v-else :style="stepStyle" progress-dot direction="vertical" class="desc">
              <a-step v-for="(item,index) in list" :key="index">
                <div slot="description" class="descDetail">
                  <div class="hisMess">
                    <div class="date">{{format.formatDayTime(item.liveStart*1000)}}</div>
                    <div class="end">{{format.liveStatus(item.liveStatus)}}</div>
                    <div class="cont">{{item.title}}</div>
                    <!-- <div v-if="item.isPayment==0&&item.liveStatus==1">
                      <img class="free" src="../../assets/img/free.png" alt="">
                    </div> -->
                    <div class="lookDetail" v-if="item.liveStatus==1" @click="watch(item)">查看详情</div>
                    <div class="lookFree" v-if="item.isPayment==0" @click="watch(item)">免费观看</div>
                  </div>
                  <div class="liveContent">{{item.liveTeacherName}} {{item.liveContent}}</div>
                </div>
              </a-step>
            </a-steps>
            <div v-if="list.length>=0" class="watchAll" @click="goAll()">
              <span>查看全部直播</span>
              <img class="moreImg" src="../../assets/img/right@2x.png" alt="">
            </div>
          </div>
        </a-col>
      </a-row>
      <div class="active">
        <div class="hotActive">近期热门活动</div>
        <div v-if="hotList.length<=0">
          <a-empty></a-empty>
        </div>
        <ul class="activeList">
          <li class="activeLi" @click="watch(item)" v-for="(item, index) in hotList" :key="index">
            <div class="imgDetail">
              <img class="coverImg" :src="resBasePath+'/'+item.livePhotoCover" alt="" />
            </div>
            <ul class="activeDesc">
              <li>
                <span class="title">{{item.title}}</span>
                <img v-if="item.isPayment==0" class="freeImg" width="18px" height="18px" src="../../assets/img/free.png"
                  alt="">
              </li>
              <li>
                <a-tooltip placement="top" :title="item.liveTeacherInfo">
                  <div class="titleName">{{item.liveTeacherName}} {{item.liveTeacherInfo}}</div>
                </a-tooltip>
              </li>
              <li>
                <a-icon type="aliwangwang" />
                <span class="watchNum">{{item.playCount || 0}}人已经观看</span>
                <span class="watchNow" @click="watch(item)">立即观看</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeadTop from '@/components/HeadTop.vue'
import format from '@/utils/format.js'
import axios from 'axios'
export default {
  name: 'active',
  data(){
    return {
        format,
        resBasePath: this.$resBasePath,
        autoplay: false,
        list:[],  //活动
        bannerList:[],// banner轮播
        hotList:[], //热播
        stepStyle: {
          width:'350px'
        },
        steps: []
    }
  },
  mounted(){
    this.getBanner()
    this.getList()
    this.getHotVideo()
  },
  methods:{
    goAll(){
      this.$router.push({name: 'allActive'})
    },
    getBanner(){
      let _this = this
      axios({
          url: this.$baseServer + 'ebook/officialLive/getOfficialLiveBannerList',
          method: 'POST',
          data:{}
      }).then(res => {
          if(res && res.data && res.data.resultCode ===0){
            _this.bannerList = res.data.resultData
          }
      })
    },
    getHotVideo(){ //ebook/officialLive/getOfficialHotLiveList
      let _this = this
      axios({
          url: this.$baseServer + 'ebook/officialLive/getOfficialHotLiveList',
          method: 'POST',
          data:{}
      }).then(res => {
          if(res && res.data && res.data.resultCode ===0){
            _this.hotList = res.data.resultData
          }
      })
    },
    getList(){ // 直播安排列表
      let _this = this
      axios({
          url: this.$baseServer + 'ebook/officialLive/getOfficialLiveHistoryList',
          method: 'POST',
          data:{}
      }).then(res => {
          if(res && res.data && res.data.resultCode ===0){
            _this.list = res.data.resultData.slice(0,5)
          }
      })
    },
    bannerDetail(a){
      // console.log(this.bannerList[a])
    },
    watch(item) {
      axios({
        url: this.$baseServer + 'ebook/officialLive/savePlayCount',
        method: 'POST',
        data: {
          id: item.id
        }
      }).then(res => {
      })
      sessionStorage.setItem('videoDetail',JSON.stringify(item))
      this.$router.push({name: 'activeVideo'})
    }
  },
  components: {
    HeadTop
  }
}
</script>
<style scoped lang="less">
  ul,li{
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .home{
    background: #f6f6f6;
    height: 100%;
    width: 100%;
    .content{
      width: 1140px;
      min-height: 500px;
      margin: 30px auto 50px;
      //顶部样式
      .top{
        // height: 440px;
        width: 100%;
        background: #fff;
        .left{
          height: 100%;
        }
        .right{
          height: 100%;
          padding-left: 20px;
          .timeLine{
            width: 100%;
            height: 100%;
            margin-top: 10px;
            .plan{
              height: 50px;
              font-size: 16px;
              font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
              font-weight: 500;
              color: #000000;
              line-height: 40px;
            }
            .desc{
              width: 540px;
              .descDetail{
                width: 540px;
                padding-left: 20px;
                margin-top: 0px;
                position: absolute;
                top: 0px;
                  .hisMess{
                    display: flex;
                    width: 100%;
                    .date{
                      width: 80px;
                      height: 24px;
                      line-height: 24px;
                      text-align: center;
                      border-radius: 4px;
                      border: 1px solid #C59A61;
                      color: #C59A61;
                    }
                    .end{
                      color: #C59A61;
                      font-weight: 500;
                      font-size: 14px;
                      display: inline-block;
                      height: 24px;
                      line-height: 24px;
                      margin-left: 10px;
                    }
                    .cont{
                      margin-left: 5px;
                      display: inline-block;
                      width: 200px;
                      font-size: 12px;
                      line-height: 26px;
                      color: #000;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    }
                    .free{
                      width: 40px;
                      height: 18px;
                      
                    }
                    .lookDetail{
                      width: 80px;
                      height: 24px;
                      border-radius: 4px;
                      line-height: 24px;
                      background: #DB5647;
                      color: #fff;
                      font-size: 14px;
                      text-align: center;
                      float: right;
                      cursor: pointer;
                    }
                    .lookFree{
                      width: 80px;
                      height: 24px;
                      border-radius: 4px;
                      line-height: 24px;
                      background: #FDF5F4;
                      color: #DB5647;
                      font-size: 14px;
                      text-align: center;
                      float: right;
                      cursor: pointer;
                    }
                  }
                  .liveContent{
                    color: #7F7F7F;
                    line-height: 24px;
                    font-size: 14px;
                    text-indent: 110px;
                  }

              }
            }
            .watchAll{
              width: 160px;
              height: 26px;
              font-size: 14px;
              font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
              font-weight: 400;
              color: #DB5647;
              line-height: 26px;
              cursor: pointer;
              .moreImg{
                width: 14px;
                height: 14px;
                margin-top: -2px;
                margin-left: 5px;
              }
            }
          }
        }
      }
      //近期热门活动样式
      .active{
        margin-top: 30px;
        .hotActive{
          height: 40px;
          font-size: 24px;
          font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
          font-weight: 500;
          color: #000000;
          line-height: 40px;
        }
        .activeList{
          margin-top: 20px;
          display: flex;
          flex-wrap: wrap;
          .activeLi{
            width :calc(25% - 20px);
            cursor: pointer;
            height: 280px;
            margin: 10px 10px;
            background: #fff;
            .imgDetail{
              height: 160px;
              .coverImg{
                height: 100%;
                width: 100%;
              }
            }
            .activeDesc{
              margin-top: 10px;
              padding: 0px 10px;
              .title{
                display: inline-block;
                width: 220px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space:nowrap;
                height: 26px;
                font-size: 16px;
                font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
                font-weight: 500;
                color: #000000;
                line-height: 26px;
                cursor: pointer;
              }
              .freeImg{
                width: 25px;
                height: 20px;
                margin-top: 2px;
                float: right;
              }
              .titleName{
                height: 35px;
                font-size: 12px;
                font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
                font-weight: 400;
                color: #000000;
                line-height: 25px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space:nowrap;
            }
            .watchNum{
              height: 18px;
              font-size: 12px;
              font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
              font-weight: 400;
              color: #7F7F7F;
              line-height: 18px;
              margin-left: 5px;
            }
            .watchNow{
              display: inline-block;
              width: 60px;
              height: 24px;
              background: linear-gradient(122deg, #FF8D46 0%, #FA5542 100%);
              border-radius: 4px;
              font-weight: 400;
              color: #FFFFFF;
              font-size: 12px;
              line-height: 24px;
              text-align: center;
              float: right;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  }
/deep/.ant-steps-dot .ant-steps-item-content, .ant-steps-dot.ant-steps-small .ant-steps-item-content{
  width: 640px;
}
/deep/.ant-steps-vertical .ant-steps-item-content{
  min-height: 42px;
}
</style>
<style scoped>
  .ant-carousel >>> .slick-slide {
  text-align: center;
  height: 390px;
  overflow: hidden;
}
.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}
.ant-carousel .slick-slide img {
    display: block;
    height: 100%;
    width: 100%;
}
.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
</style>